import {Box, Typography} from "@mui/material";

export default function CardHeaderLayer2(props) {

  const style = {
    backgroundColor: "cardHeaderColor.secondary"
  }

  return (
    <Box sx={style} py={{xs: 1, md: 1}} px={{xs: 2, md: 3}}>
      <Typography variant="h7">
        {props.text}
      </Typography>
    </Box>
  )
}