import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Head from "../components/head/Head";
import React from "react";

function ThanksPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  return (
    <>
      <Head title={"終了"} />
      <Container maxWidth="lg">
        <Grid sx={{
          height: "100vh"
        }} container justifyContent="center" alignItems="center">
          <Box width={"480px"} justifyContent={"center"}>
            <Typography variant="h4" textAlign="center">ご回答ありがとうございました</Typography>
            <Typography textAlign="center" mt={10}>このままブラウザもしくはタブを閉じてください</Typography>
            <Typography
              textAlign="center"
              mt={4}>再度回答をご確認いただきたい場合は、              現在ログイン中のURL、パスコードを入力するとご確認いただけます。</Typography>
          </Box>
        </Grid>
      </Container>
    </>
  );
}

export default ThanksPage;
