import { useEffect, useState } from "react";

export function getImageRatio() {
    return 1;
}


/// {elements}
export function DrawElement(props) {

    const { createCanvas, loadImage } = require('canvas')

    const [imgSrc, setImgSrc] = useState("");

    useEffect(() => {
    }, []);

    const polygonList = JSON.parse(props.element.objectsOnDrawingsJson)

    loadImage(props.imgSrc)
        .then(image => {
            const canvas = createCanvas(image.width, image.height)
            const ctx = canvas.getContext('2d')

            //画像読込
            ctx.drawImage(image, 0, 0, image.width, image.height)

            polygonList.forEach((polygon) => {
                DrawPolygon(ctx, polygon, false);
            });

            setImgSrc(canvas.toDataURL())
        });

    return (<img src={imgSrc} />);

}

export function DrawFree(props) {

    const { createCanvas, loadImage } = require('canvas')

    const [imgSrc, setImgSrc] = useState("");

    useEffect(() => {
    }, []);

    loadImage(props.imgSrc)
        .then(image => {
            const canvas = createCanvas(image.width, image.height)
            const ctx = canvas.getContext('2d')

            //画像読込
            ctx.drawImage(image, 0, 0, image.width, image.height)

            props.frees.forEach((free) => {
                const polygonList = JSON.parse(free.itemOnDrawingsJson)
                polygonList.forEach((poly) => {
                    DrawPolygon(ctx, poly, true)
                })

            })

            setImgSrc(canvas.toDataURL())
        });

    return (<img src={imgSrc} />);

}

function DrawPolygon(ctx, polygon, isLabel) {

    let color;
    const rgb = polygon.color.split(', ');
    if (rgb.length == 3 || rgb.length == 4) {
        color = "#"
            + ('00' + Number(rgb[0]).toString(16)).slice(-2)
            + ('00' + Number(rgb[1]).toString(16)).slice(-2)
            + ('00' + Number(rgb[2]).toString(16)).slice(-2)
    } else {
        color = polygon.color;
    }


    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    if (polygon.label && isLabel) {
        ctx.beginPath();

        const position = polygon.positionList[0];
        const fontSize = 16;
        const deltaX = 6;
        const deltaY = 2;

        ctx.fillStyle = "white";
        ctx.font = 'bold ' + (fontSize + 2) + 'px Impact'
        ctx.fillText(polygon.label, position.x + deltaX, position.y + fontSize + 1 + deltaY)

        ctx.fillStyle = color;
        ctx.font = fontSize + 'px Impact'
        ctx.fillText(polygon.label, position.x + deltaX, position.y + fontSize + 2 + deltaY)
        ctx.closePath();

    }

    switch (polygon.shape) {
        case 0:
            if (polygon.positionList.length == 1) {
                const position = polygon.positionList[0];
                ctx.beginPath();

                ctx.arc(position.x, position.y, 5, 0 * Math.PI / 180, 360 * Math.PI / 180, false);
                ctx.fill();

                ctx.lineWidth = 3;
                ctx.stroke();
                ctx.closePath();

            }
        case 1:
        case 2:
            ctx.beginPath();
            polygon.positionList.forEach((position) => {
                ctx.lineTo(position.x, position.y)
            })
            ctx.stroke();
            break;
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            ctx.beginPath();
            polygon.positionList.forEach((position) => {
                ctx.lineTo(position.x, position.y)
            })
            ctx.closePath();
            ctx.stroke();
            break;
    }
}
