import React from "react";
import {
  Box,
  Card,
  Container,
  CssBaseline,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import QAndATable from "../components/table/QAndATable";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Head from "../components/head/Head";
import CancelButton from "../components/buttons/CancelButton";
import MainButton from "../components/buttons/MainButton";
import CardHeader from "../components/card/CardHeader";
import CardBody from "../components/card/CardBody";
import CardFooter from "../components/card/CardFooter";
import CardHeaderLayer2 from "../components/card/CardHeaderLayer2";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { fabric } from "fabric";
import { FreesToPolygons } from "../util/UtilFunctions.js";
import DrawCanvas from "../components/table/DrawCanvas";
import { getApiURL } from "../util/ApiBase";
import { GoToTop } from "../util/NavigatePage";

function QAndAPage(ref) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  const [data, setData] = React.useState(null);
  const [final, setFinal] = React.useState(null);
  const [floorId, setFloorId] = React.useState();
  const [imageLabel, setImageLabel] = React.useState('');
  const [imageFloor, setImageFloor] = React.useState();
  const [imgLoading, setImgLoading] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  const [points, setPoint] = React.useState([]);
  const [polygons, setPolygons] = React.useState([]);

  const [fileName, setFileName] = React.useState('');

  const getAnswer = async () => {
    if (loading) {
      await fetch(getApiURL(`/questionnaireById/${display_id}`))
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then(response => {
          setFileName(response.fileName.split("\\").pop());
        })
        .catch(error => {
        })
        .finally(() => {
        })
    } else {
      return;
    }
  }

  const onChange = (data) => {
    setPoint(data);
  };

  const fetchQuestions = async (idFloor) => {
    if (data != null) {
      return;
    }
    await fetch(getApiURL(`/GetFreeByDisplayId/${display_id}/` + idFloor))
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw response;
      })
      .then((response) => {
        setData(response);
        setPolygons(FreesToPolygons(response));
        setFloorId(response[0].id_FLOOR.id);
        const label = "図面 " + response[0].id_FLOOR.floorName;
        setImageLabel(label);
      })
      .catch((error) => {
        console.log("Failed to fetch the api: ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });

    try {
      const image = await axios.get(
        getApiURL(`/GetImageFloor/${idFloor}`),
        {
          responseType: "arraybuffer",
        }
      );
      const base64 = btoa(
        new Uint8Array(image.data).reduce(
          (image, byte) => image + String.fromCharCode(byte),
          ""
        )
      );
      setImageFloor(`data:;base64,${base64}`);
      setImgLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSave = () => {
    const response = data;

    if (final === null) {
      GoToTop(navigate, location, display_id);
    }
    final?.map((row) => {
      const saveQuestion = {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          answer: row.answer,
          id_FREE_QUESTION: row.id_FREE_QUESTION,
          isAnswered: 1,
        }),
      };
      fetch(getApiURL("/updateQuestion"), saveQuestion)
        .then((res) => res.json())
        .then((res) => {
          onCancel();
        })
        .catch(function (error) {
          console.log(error);
        });
    });
    setFinal(null);
  };

  const onContinue = async () => {
    console.log(floorId);
    setData(null);
    //setLoading(true);
    fetchQuestions();
    var position = [];
    var ind = 0;
    points.map((row, index) => {
      const data = {
        x: row[0],
        y: row[1],
      };
      ind = index;
      position.push(data);
    });

    final?.map((row) => {
      const saveQuestion = {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          answer: row.answer,
          id_FREE_QUESTION: row.id_FREE_QUESTION,
          isAnswered: 1,
        }),
      };
      fetch(getApiURL("/updateQuestion"), saveQuestion)
        .then((res) => res.json())
        .then((response) => {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    await fetch(getApiURL(`/getFloorByIdLimit/${display_id}/` + floorId))
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((response) => {
        setFloorId(response.id);
        fetch(getApiURL(
          "/free_questionsByFloor/" + response.id)
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }

            throw response;
          })
          .then(async (response) => {


            await fetch(getApiURL(`/GetFreeByDisplayId/${display_id}/` + response[0].id_FLOOR.id))
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }

                throw response;
              })
              .then((response) => {
                setData(response);
                setPolygons(FreesToPolygons(response));
                const label = "図面 " + response[0].id_FLOOR.floorName;
                setImageLabel(label);
              })

            setImageFloor(null);
            setImgLoading(true);
            const image = await axios.get(
              getApiURL(`/GetImageFloor/${response[0].id_FLOOR.id}`),
              {
                responseType: "arraybuffer",
              }
            );
            const base64 = btoa(
              new Uint8Array(image.data).reduce(
                (image, byte) => image + String.fromCharCode(byte),
                ""
              )
            );
            setImageFloor(`data:;base64,${base64}`);
            setImgLoading(false);
          })
          .catch((error) => {
            console.log("Failed to fetch the api: ", error);
            setError(error);
          });
      })
      .catch((error) => {
        console.error("Failed to fetch the api : ", error);
        setError(error);
        alert("次の質疑はありません。");
        GoToTop(navigate, location, display_id);
      });
  };

  const onCancel = async () => {
    GoToTop(navigate, location, display_id);
  };

  React.useEffect(() => {
    fetchQuestions(location.state.id);
    getAnswer(location.state.id);

  }, [loading]);

  return (
    <>
      <Head title={"質疑の回答"} />
      <CssBaseline />
      <Box height={"100vh"} display={"flex"} flexDirection={"column"}>
        <Header />
        <Container maxWidth="lg" sx={{ paddingY: { xs: "30px", md: "40px" } }}>
          <Card>
            <CardHeader text={"質疑の回答"} />
            <CardBody>
              <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: 'row-reverse',
                mb: 1
              }}>
                ファイル名：{fileName}
              </Box>
              <Card>
                <CardHeaderLayer2 text={imageLabel} />
                <CardBody>
                  {imgLoading ? (
                    <Grid container justifyContent="center">
                      <Grid>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="center">

                      <DrawCanvas
                        initialData={polygons}
                        onChange={onChange}
                        imgSrc={imageFloor}
                        frees={data}
                      />
                    </Grid>
                  )}
                </CardBody>
              </Card>
              <Box sx={{ marginTop: "40px" }}>
                <QAndATable question={data} setFinal={setFinal} />
              </Box>
            </CardBody>
            <CardFooter>
              <Grid container justifyContent="end" spacing={1}>
                <Grid item xs={12} md={"auto"} sx={{ marginRight: "auto" }}>
                  <CancelButton onClick={onCancel} />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  {/* <ExcelButton apiData={data} fileName="TONE_question" /> */}
                  <a
                    href={getApiURL(`/export-excel/${floorId}`)}
                    target={"_blank"}
                  >
                    <Button variant="contained" color="success">
                      EXCEL出力
                    </Button>
                  </a>
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <MainButton onClick={onSave} text={"保存してトップへ"}
                    disabled={location.state.answered} />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <MainButton onClick={onContinue} text={"保存して次へ"}
                    disabled={location.state.answered} />
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </Container>
        <Box mt={"auto"}>
          <Footer />
        </Box>
      </Box>
    </>
  );
}

export default QAndAPage;
