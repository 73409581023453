import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Paper } from '@mui/material';
import QAndAModal from "../modal/QAndAModal";
import { polygonToColor, polygonToShape } from "../../util/UtilFunctions";
import pen from '../../img/pen-to-square-regular.svg';

const createTable = (handleOpen, data) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row" width={50}
              align="center"></TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row" width={61}
              align="center">番号</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row" width={61}
              align="center">色</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row" width={91}
              align="center">形状</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">質疑内容</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">回答</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((floorData) => (
            <TableRow
              key={floorData.id_FREE_QUESTION}
              sx={{}}
              hover
              onClick={(event) => {
                handleOpen(floorData.id_FREE_QUESTION, floorData.answer, floorData.question)
              }}
            >
              <TableCell align="center"><img src={pen} alt="" /></TableCell>
              <TableCell align="center">{floorData.questionIndex}</TableCell>
              <TableCell align="center">
                <Box backgroundColor={
                  polygonToColor(JSON.parse(floorData.itemOnDrawingsJson)[0])
                }>　</Box>
              </TableCell>
              <TableCell align="center">{
                polygonToShape(JSON.parse(floorData.itemOnDrawingsJson)[0])
              }</TableCell>
              <TableCell align="left">{floorData.question}</TableCell>
              <TableCell align="left">{floorData.answer}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  )
}

export default function BasicTable({ question, setFinal }) {
  const [open, setOpen] = React.useState(false);
  const [answer, setAnswer] = React.useState('');
  const [quest, setQuest] = React.useState('');
  const [id, setId] = React.useState();

  const handleOpen = (id, ans, questions) => {
    setAnswer(ans);
    setQuest(questions);
    setId(id);
    setOpen(true)
  };

  const handleClose = () => setOpen(false);

  const onSubmit = () => {
    question?.map(element => {
      if (id === element.id_FREE_QUESTION) {
        element.answer = answer;
        setOpen(false);
      }
    })
    setFinal(question);
    setAnswer('');

  }

  return (
    <>
      <QAndAModal setAnswer={setAnswer} answer={answer} question={quest} open={open} handleClose={handleClose} onSubmit={onSubmit} />

      {createTable(handleOpen, question)}
    </>
  );
}
