import {Box, Container} from "@mui/material";

export default function Footer() {
  return (
    <Container maxWidth="lg">
      <Box bgcolor={"pageHeaderColor.main"} height={{xs: "50px", md: "60px"}} display={"flex"} alignItems={"center"}
           justifyContent={"center"}>
        ©︎2022 s-build
      </Box>
    </Container>
  )
}