import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getApiURL } from "../../util/ApiBase";
import { zoneToName, statusToName } from "../../util/UtilFunctions";

export default function HistoryProductNumber(props) {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  const [history, setHistory] = useState([]);

  const getHistory = async () => {
    await fetch(getApiURL('/getElementsHistory?displayId=' + display_id))
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(response => {
        setHistory(response);
        window.console.log(response);
      })
      .catch(error => {
        console.error("Failed to fetch the api: ", error);
      })
      .finally(() => {
      })
  }


  useEffect(() => {
    getHistory();
  }, [])


  const headerStyle = {
    backgroundColor: "cellHeaderColor.main",
    border: 1,
    borderColor: "#e0e0e0",
    align: "center"
  }

  const headerSmallStyle = {
    ...headerStyle,
    maxWidth: "100px",
    minWidth: "100px",
  }

  const headerMiddleStyle = {
    ...headerStyle,
    maxWidth: "120px",
    minWidth: "120px",
  }

  const headerLargeStyle = {
    ...headerStyle,
    maxWidth: "150px",
    minWidth: "150px",
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table" >
        <TableHead>
          <TableRow>
            <TableCell sx={headerStyle}
              colSpan={6}>基礎情報</TableCell>
            <TableCell sx={headerStyle} colSpan={3}>回答前</TableCell>
            <TableCell sx={headerStyle} colSpan={4}>回答後</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={headerLargeStyle}>発行日時</TableCell>
            <TableCell sx={headerLargeStyle}>回答日時</TableCell>
            <TableCell sx={headerLargeStyle}>フロア名</TableCell>
            <TableCell sx={headerSmallStyle}>モード</TableCell>
            <TableCell sx={headerLargeStyle}>部屋名</TableCell>
            <TableCell sx={headerLargeStyle}>エリア</TableCell>
            <TableCell sx={headerLargeStyle}>販売元</TableCell>
            <TableCell sx={headerLargeStyle}>品番</TableCell>
            <TableCell sx={headerLargeStyle}>部材名</TableCell>
            <TableCell sx={headerLargeStyle}>販売元</TableCell>
            <TableCell sx={headerLargeStyle}>品番</TableCell>
            <TableCell sx={headerLargeStyle}>部材名</TableCell>
            <TableCell sx={headerMiddleStyle}>ステータス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            history && history?.map((row) => (
              <TableRow key={row.id_ELEMENT}>
                <TableCell>{row.id_FLOOR?.questionnaire.createDateTime?.slice(0, -3).replace("T", " ") ?? ""}</TableCell>
                <TableCell>{row.id_FLOOR?.questionnaire?.submitDateTime?.slice(0, -3).replace("T", " ") ?? ""}</TableCell>
                <TableCell>{
                  row.id_FLOOR?.floorName}</TableCell>
                <TableCell>{zoneToName(row.zone)}</TableCell>
                <TableCell>{row.roomName}</TableCell>
                <TableCell>{row.areaName}</TableCell>
                <TableCell>{row.initSaler}</TableCell>
                <TableCell>{row.initSerialCode}</TableCell>
                <TableCell>{row.initElementName}</TableCell>
                <TableCell>{row.saler}</TableCell>
                <TableCell>{row.serialCode}</TableCell>
                <TableCell>{row.elementName}</TableCell>
                <TableCell>{statusToName(row.status)}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
}
