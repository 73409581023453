import Button from "@mui/material/Button";
import React from "react";

function MainButton(props) {
  const style = {
    width: { xs: "100%", md: "auto" },
    fontSize: { xs: "12px", md: "14px" },
    ...props.style,
  }

  return (
    <Button name={props.name} size={props.size ?? "normal"} onClick={props.onClick} disabled={!!props.disabled} sx={style}
      variant="contained">{props.text}</Button>
  );
}

export default MainButton;
