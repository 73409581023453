import * as React from 'react';
import { useLocation } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CancelButton from "../buttons/CancelButton";
import MainButton from "../buttons/MainButton";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 350, md: 600 },
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

export default function TransitionsModal(props) {
  const location = useLocation();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <Box bgcolor={"cardHeaderColor.main"} height={"50px"} borderRadius={1} display={"flex"} alignItems={"center"}
            justifyContent={"center"}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              回答
            </Typography>
          </Box>
          <Box mt={4}>
            <Box>
              <Typography id="transition-modal-description">
                質問
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                {props.question}
              </Typography>
            </Box>
            <Box mt={6}>
              <Typography id="transition-modal-description">
                回答
              </Typography>
              <TextField
                InputProps={{
                  readOnly: location.state.answered,
                }}
                id="outlined-multiline-static"
                label=""
                multiline
                rows={4}
                defaultValue={props.answer}
                fullWidth
                onChange={(e) => props.setAnswer(e.target.value)}
                sx={{ mt: 2 }}
              />
            </Box>
          </Box>
          <Box bgcolor={"cardFooterColor.main"} borderRadius={1} mt={4} px={{ xs: 2, md: 3 }} py={2}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <CancelButton onClick={props.handleClose} />
              </Grid>
              <Grid item>
                <MainButton
                  disabled={
                    location.state.answered
                  }
                  onClick={props.onSubmit} text={"保存"} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
    ;
}