import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import pen from '../../img/pen-to-square-regular.svg';
import { GoToEditElement } from '../../util/NavigatePage';

export default function BasicTable({ productData }) {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  const statusChange = (status) => {
    if (status === 4) {
      return "未回答";
    } else if (status === 5) {
      return "未確定";
    } else if (status === 6) {
      return "シリーズのみ確定";
    } else if (status === 7) {
      return "部材確定";
    } else {
      return "";
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">編集</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">フロア名</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">モード</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">部屋名</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">エリア</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">部材名</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">品番</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">ステータス</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">販売元</TableCell>
            <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
              align="center">回答</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            productData && productData?.map((row) => (
              <TableRow
                key={row.key}
                sx={{
                  cursor: "pointer"
                }}
                //hover={true}
                onClick={() =>
                  GoToEditElement(navigate, location, row.id_ELEMENT, display_id)
                }
              >
                <TableCell sx={{
                  backgroundColor: "rgba(0,0,0,0)",
                  transition: "0.2s",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                  }
                }} align="center">
                  <Box>
                    <img
                      src={pen} alt="" width={15} height={15} onClick={() =>
                        GoToEditElement(navigate, location, row.id_ELEMENT, display_id)
                      } />
                  </Box>
                </TableCell>
                <TableCell align="center">{row.id_FLOOR.floorName}</TableCell>
                <TableCell align="center">{function () {
                  switch (row.zone) {
                    case 100:
                      return "床";
                    case 200:
                      return "天井";
                    case 300:
                      return "壁";
                    case 400:
                      return "間仕切";
                    case 500:
                      return "建材";
                    case 600:
                      return "巾木";
                  }
                }()}</TableCell>
                <TableCell align="center">{row.roomName}</TableCell>
                <TableCell align="center">{row.areaName}</TableCell>
                <TableCell align="center">{row.elementName}</TableCell>
                <TableCell align="center">{row.serialCode}</TableCell>
                <TableCell align="center">{statusChange(row.status)}</TableCell>
                <TableCell align="center">{row.saler}</TableCell>
                <TableCell align="center">
                  {row.isAnswered === 0 ? <Button sx={{
                    backgroundColor: "cellAnswerColor.main",
                    opacity: 0.85,
                    transition: "0.2s",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "cellAnswerColor.main",
                      opacity: 1,
                    },
                  }}>未回答</Button> : <Button variant="contained"
                  >回答済み</Button>}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
}