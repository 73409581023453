import styled from "styled-components";

export const TABLE = styled.table`
  display: flex;
  flex-direction: column;
  border: 0px outset;
  border-color: #EEEEEE;
  border-radius: 5px;
  border-collapse: collapse;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.39);
`
export const HEAD = styled.thead`
  background-color: #EEEEEE;
  border-radius: 5px 5px 0 0;
`
export const ROW = styled.tr`
  display: flex;
  justify-content: space-evenly;
  &:hover {
    cursor: pointer;
  }
  align-items: center;
  border: 1px solid;
  border-color: #E0E0E0;
`
export const HEADCELL = styled.td`
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  padding: 16px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
`
export const CELL = styled.td`
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  padding: 10px;
  text-align: center;
  width: 90px;
 `
export const FCELL = styled.td`
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  padding: 10px;
  text-align: center;
  width: 100px;
`
export const COLUMNCELL = styled.td`
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 75px;
  border-bottom: 1px solid;
  border-color: #E0E0E0;
`
export const BODYCELL = styled.tr`
  display: flex;
  flex-direction: column;
`