import {createTheme} from "@mui/material";
import {grey, red, lightGreen} from "@mui/material/colors";

export default class SettingTheme {

  static get get() {
    return createTheme({
      palette: {
        // primary: {
        //   main: "#316745"
        // },
        pageBgColor : {
          // main: lightGreen["50"]
          main: "#fff"
        },
        pageHeaderColor: {
          main: lightGreen["500"]
        },
        pageFooterColor: {
          main: lightGreen["300"]
        },
        cardHeaderColor: {
          main: lightGreen["300"],
          secondary: lightGreen["200"]
        },
        cardFooterColor: {
          main: grey["300"]
        },
        cellHeaderColor: {
          main: grey["200"]
        },
        cellAnswerColor: {
          main: red["500"]
        },
        cancel: {
          main: grey["900"]
        },
        logout: {
          main: grey["900"]
        }
      },
      typography: {
        // htmlFontSize: 20,
        h5: {
          fontSize: '1.3rem',
          '@media (min-width:600px)': {
            fontSize: '1.5rem',
          },
        }
      }
    });
  }
}