import SettingTheme from "./setting/SettingTheme";
import SettingRoutes from "./setting/SettingRoutes";
import {ThemeProvider} from "@mui/material";

function App() {
  return (
    <ThemeProvider theme={SettingTheme.get}>
      <SettingRoutes/>
    </ThemeProvider>
  );
}

export default App;
