export function getApiBaseURL() {
    const baseURL = window.location.host === 'localhost:3015' ? 'http://localhost:29521' :
        window.location.host === 'qa891839483.cob-info.com' ? 'http://qa891839483.cob-info.com:29521'
            : 'http://qa.cob-info.com:29521';
    //private baseURL = 'http://localhost:29521';

    return baseURL;
}

export function getApiURL(path) {
    const baseURL = window.location.host === 'localhost:3015' ? 'http://localhost:29521' :
        window.location.host === 'qa891839483.cob-info.com' ? 'https://qa891839483.cob-info.com:29521' :
            'https://qa.cob-info.com:29521';
    //private baseURL = 'http://localhost:29521';

    return baseURL + path;
}
