import { Grid, Box, Container, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../../img/s-build-logo.png';
import * as React from "react";
import LogoutButton from "../buttons/LogoutButton";
import InitButton from "../buttons/InitButton";
import ConfirmReset from "../modal/ConfirmReset";
import { GoToHistory } from "../../util/NavigatePage";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container maxWidth="lg">
      <Box bgcolor={"pageHeaderColor.main"} height={{ xs: "50px", md: "70px" }} px={{ xs: 2, md: 4 }} py={{ xs: 1, md: 2 }}
        display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <img height={"100%"} src={logo} alt="" />
        <Grid container justifyContent={"end"} spacing={1}>

          <Grid item md={"auto"}>
            <Button style={{
              backgroundColor: "#fff",
            }} size={"small"}
              onClick={() => {
                GoToHistory(navigate, location, display_id);
              }}>回答履歴</Button>
          </Grid>
          <Grid item md={"auto"}>
            <InitButton onClick={handleOpen}
              disabled={location.state.answered} />
          </Grid>
          <Grid item md={"auto"}>
            <LogoutButton />
          </Grid>
          <ConfirmReset open={open} handleClose={handleClose} />
        </Grid>
      </Box>
    </Container>
  )
}