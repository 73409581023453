import Button from "@mui/material/Button";
import React from "react";

function CancelButton(props) {
  const style = {
    width: {xs: "100%", md: "auto"},
    backgroundColor: "#fff",
    fontSize: {xs: "12px", md: "14px"},
  }

  return (
    <Button onClick={props.onClick} sx={style} variant="outlined" color="cancel">
      {props.text ?? "キャンセル"}
    </Button>
  );
}

export default CancelButton;
