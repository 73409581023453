import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom";
import QAndAData from "../../jsonData/table/qAndAData.json"
import MainButton from "../buttons/MainButton";
import pen from '../../img/pen-to-square-regular.svg';
import styled from "styled-components";
import { style } from "@mui/system";
import { TABLE, BODYCELL, CELL, COLUMNCELL, FCELL, HEAD, HEADCELL, ROW } from "./styles";
import { Box, Button, Grid } from "@mui/material";
import { GoToEditFreeQuestion } from "../../util/NavigatePage";

const ICON = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
  margin-top: 5px;
`
const headerStyle = {
  fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
  fontWeight: 500,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.01071em',
  padding: '16px',
  textAlign: 'center',
}
const floorCellStyle = {
  fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.43',
  letterSpacing: '0.01071em',
  padding: '10px',
}
const ColumnCellStyle = {
  borderBottom: "1px solid",
  borderColor: "#E0E0E0",
}

export default function BasicTable({ floorData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  return (
    <TableContainer component={Paper}>
      <TABLE>
        <HEAD>
          <ROW>
            <Grid container
              justify="center"
              alignItems="center">
              <Grid item xs={1} style={headerStyle}>
                編集
              </Grid>
              <Grid item xs={2} style={headerStyle}>
                フロア名
              </Grid>
              <Grid container item xs={9} style={headerStyle}>
                <Grid item xs={1} style={headerStyle}>
                  番号
                </Grid>
                <Grid item xs={5} style={headerStyle}>
                  質疑内容
                </Grid>
                <Grid item xs={4} style={headerStyle}>
                  質疑回答
                </Grid>
                <Grid item xs={2} style={headerStyle}>
                  回答
                </Grid>
              </Grid>

            </Grid>
          </ROW>
        </HEAD>
        <tbody>

          {
            floorData?.map((row, index) => {
              if (row.floorDataList && row.floorDataList.length > 0) {

                return (

                  <ROW key={index} onClick={() =>
                    GoToEditFreeQuestion(navigate, location, row.floor, display_id)
                  }>
                    <Grid container
                      justify="center"
                      alignItems="center">
                      <Grid item xs={1} textAlign={"center"} >
                        <Box sx={{
                          backgroundColor: "rgba(0,0,0,0)",
                          transition: "0.2s",
                          padding: 3,
                          "&:hover": {
                            backgroundColor: "rgba(0,0,0,0.05)",
                          }
                        }}>
                          <img src={pen} alt="" width={15} height={15} onClick={() => GoToEditFreeQuestion(navigate, location, row.floor, display_id)} />
                        </Box>
                      </Grid>
                      <Grid item xs={2} textAlign={"left"} style={floorCellStyle}>
                        {row.floorName}
                      </Grid>
                      <Grid item xs={9} style={floorCellStyle}>
                        <BODYCELL>
                          {
                            row.floorDataList?.map((data, index) => (
                              <Grid container
                                justify="center"
                                alignItems="center"
                                style={ColumnCellStyle}>
                                <Grid item xs={1} textAlign={"center"} style={floorCellStyle}>
                                  {data.questionIndex}
                                </Grid>
                                <Grid item xs={5} textAlign={"left"} style={floorCellStyle}>
                                  {data.question}
                                </Grid>
                                <Grid item xs={4} textAlign={"left"} style={floorCellStyle}>
                                  {data.answer}
                                </Grid>
                                <Grid item xs={2} textAlign={"center"}
                                  width={120} style={floorCellStyle}>
                                  {data.isAnswered === 0 ? <Button sx={{
                                    backgroundColor: "cellAnswerColor.main",
                                    opacity: 0.85,
                                    transition: "0.2s",
                                    color: "white",
                                    "&:hover": {
                                      backgroundColor: "cellAnswerColor.main",
                                      opacity: 1,
                                    },
                                  }}>未回答</Button> : <Button variant="contained"
                                  >回答済み</Button>}
                                </Grid>
                              </Grid>
                            ))
                          }
                        </BODYCELL>
                      </Grid>
                    </Grid>
                  </ROW>
                )
              }
              return;
            })
          }
        </tbody>
      </TABLE>
    </TableContainer >
  )
}


