import Button from "@mui/material/Button";
import React from "react";


function InitButton(props) {
  //Modal

  const style = {
    backgroundColor: "#fff",
  }

  return (
    <Button onClick={props.onClick} style={style} size={"small"}
      variant="outlined"
      disabled={!!props.disabled}>質疑をリセット
    </Button>

  );
}

export default InitButton;
