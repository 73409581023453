import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getApiURL } from "../../util/ApiBase";
import { zoneToName, statusToName } from "../../util/UtilFunctions";

export default function HistoryQAndA(props) {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  const [history, setHistory] = useState([]);

  const getHistory = async () => {
    await fetch(getApiURL('/getFreeQuestionsHistory?displayId=' + display_id))
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(response => {
        setHistory(response);
        window.console.log(response);
      })
      .catch(error => {
        console.error("Failed to fetch the api: ", error);
      })
      .finally(() => {
      })
  }


  useEffect(() => {
    getHistory();
  }, [])


  const headerStyle = {
    backgroundColor: "cellHeaderColor.main",
    border: 1,
    borderColor: "#e0e0e0",
    align: "center"
  }

  const headerSmallStyle = {
    ...headerStyle,
    maxWidth: "80px",
    minWidth: "80px",
  }

  const headerMiddleStyle = {
    ...headerStyle,
    maxWidth: "150px",
    minWidth: "150px",
  }

  const headerLargeStyle = {
    ...headerStyle,
    maxWidth: "250px",
    minWidth: "250px",
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table" >
        <TableHead>
          <TableRow>
            <TableCell sx={headerMiddleStyle}>発行日時</TableCell>
            <TableCell sx={headerMiddleStyle}>回答日時</TableCell>
            <TableCell sx={headerMiddleStyle}>フロア名</TableCell>
            <TableCell sx={headerSmallStyle}>番号</TableCell>
            <TableCell sx={headerLargeStyle}>質問</TableCell>
            <TableCell sx={headerLargeStyle}>回答前</TableCell>
            <TableCell sx={headerLargeStyle}>回答後</TableCell>
            <TableCell sx={headerMiddleStyle}>ステータス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            history && history?.map((row) => (
              <TableRow key={row.id_ELEMENT}>
                <TableCell>{row.id_FLOOR?.questionnaire.createDateTime?.slice(0, -3).replace("T", " ") ?? ""}</TableCell>
                <TableCell>{row.id_FLOOR?.questionnaire?.submitDateTime?.slice(0, -3).replace("T", " ") ?? ""}</TableCell>
                <TableCell>{
                  row.id_FLOOR?.floorName}</TableCell>
                <TableCell>{row.questionIndex}</TableCell>
                <TableCell>{row.question}</TableCell>
                <TableCell>{row.initAnswer}</TableCell>
                <TableCell>{row.answer}</TableCell>
                <TableCell>{row.isAnswered == 0 ? "未回答" : "回答済み"}</TableCell>

              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
}
