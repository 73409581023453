import * as React from "react"
import { Helmet } from "react-helmet"

// ページコンポーネントから、titleをpropしています
const Head = (props) => {
  // const {title} = props
  return (
    <Helmet>
      <title>{props.title}</title>
    </Helmet>
  )
}

export default Head