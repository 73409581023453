import Button from "@mui/material/Button";
import React from "react";

function ResetButton(props) {
  const style = {
    width: { xs: "100%", md: "auto" },
    backgroundColor: "#fff",
    fontSize: { xs: "12px", md: "14px" },
  }

  return (
    <Button onClick={props.onClick} sx={style}
      variant="outlined"
      disabled={!!props.disabled}>リセット</Button>
  );
}

export default ResetButton;
