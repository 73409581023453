
export function polygonToColor(polygon) {
    if (!polygon?.color) {
        return "";
    }
    let color;
    const rgb = polygon.color.split(', ');
    if (rgb.length == 3 || rgb.length == 4) {
        color = "#"
            + ('00' + Number(rgb[0]).toString(16)).slice(-2)
            + ('00' + Number(rgb[1]).toString(16)).slice(-2)
            + ('00' + Number(rgb[2]).toString(16)).slice(-2)
    } else {
        color = polygon.color;
    }
    return color;
}

export function polygonToShape(polygon) {
    switch (polygon?.shape) {
        case 0:
            return "点";
        case 1:
        case 2:
            return "線";
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            return "エリア";
    }
    return "";
}

export function zoneToName(zone) {
    switch (zone) {
        case 100:
            return "床";
        case 200:
            return "天井";
        case 300:
            return "壁";
        case 400:
            return "間仕切";
        case 500:
            return "建材";
        case 600:
            return "巾木";
    }
    return "";
}
export function statusToName(status) {
    switch (status) {
        case 1:
        case 2:
        case 3:
            return "";
        case 4:
            return "未回答";
        case 5:
            return "未確定";
        case 6:
            return "シリーズのみ確定";
        case 7:
            return "部材確定";
    }
    return "";
}

export function getImageRatio() {
    return 1;
}

export function FreesToPolygons(frees) {
    var polygons = {};
    frees.forEach((free) => {
        const polygonList = JSON.parse(free.itemOnDrawingsJson)
        polygonList.forEach((poly) => {

            const polyName = "Polygon_" + (1 + Math.floor(Math.random() * 1000000)).toString();
            polygons[polyName] = PolygonConvert(poly);
        })

    })
    return polygons;
}

export function ElementToPolygons(Element) {
    var polygons = {};
    const polygonList = JSON.parse(Element.objectsOnDrawingsJson)
    polygonList.forEach((poly) => {

        const polyName = "Polygon_" + (1 + Math.floor(Math.random() * 1000000)).toString();
        polygons[polyName] = PolygonConvert(poly);
    })

    return polygons;
}
function PolygonConvert(poly) {

    var polygon = [];
    poly.positionList.forEach((position) => {
        const p = new Array(position.x * getImageRatio(), position.y * getImageRatio());
        polygon.push(p)
    })
    switch (poly.shape) {
        case 0:
            if (polygon.length == 1) {
                const centerX = polygon[0][0];
                const centerY = polygon[0][1];
                const diameter = 4;

                polygon.push(new Array(centerX - diameter, centerY));
                polygon.push(new Array(centerX, centerY - diameter));
                polygon.push(new Array(centerX + diameter, centerY));
                polygon.push(new Array(centerX, centerY + diameter));
                polygon.push(new Array(centerX - diameter, centerY));
            }
        case 1:
        case 2:
            for (let i = polygon.length - 2; i > 0; i--) {
                polygon.push(polygon[i])
            }
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            break;

    }
    return polygon;
}