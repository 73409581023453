import {Box, Typography} from "@mui/material";

export default function CardHeader(props) {

  const style = {
    backgroundColor: "cardHeaderColor.main"
  }

  return (
    <Box sx={style} py={{xs: 2, md: 2}} px={{xs: 2, md: 5}}>
      <Typography variant="h5">
        {props.text}
      </Typography>
    </Box>
  )
}