import React from "react";
import { Box, Card, Container, CssBaseline, Typography, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Head from "../components/head/Head";
import MainButton from "../components/buttons/MainButton";
import CancelButton from "../components/buttons/CancelButton";
import ResetButton from "../components/buttons/ResetButton";
import CardHeader from "../components/card/CardHeader";
import CardBody from "../components/card/CardBody";
import CardFooter from "../components/card/CardFooter";
import CardHeaderLayer2 from "../components/card/CardHeaderLayer2";
import CardBodyLayer2 from "../components/card/CardBodyLayer2";
import DrawCanvasElement from "../components/table/DrawCanvasElement";
import { zoneToName, ElementToPolygons } from "../util/UtilFunctions.js";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiURL } from "../util/ApiBase";
import { GoToTop } from "../util/NavigatePage";


function ProductNumberPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  const [data, setData] = React.useState(null);
  const [elementId, setElementId] = React.useState(0);

  const [isAnswerd, setIsAnswerd] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  const [saler, setSaler] = React.useState('');
  const [element, setElement] = React.useState('');
  const [serialCode, setSerialCode] = React.useState('');
  const [initSaler, setInitSaler] = React.useState('');
  const [initElement, setInitElement] = React.useState('');
  const [initSerialCode, setInitSerialCode] = React.useState('');

  const [imageLabel, setImageLabel] = React.useState('');

  const [imageFloor, setImageFloor] = React.useState();
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [polygons, setPolygons] = React.useState({});


  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const markStyle = {
    color: "red",
    marginLeft: "3px",
    transform: "scale(1.3)",
    display: "inline-block"
  }
  const getAnswer = async () => {
    if (loading) {
      await fetch(getApiURL(`/questionnaireById/${display_id}`))
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then(response => {
          setFileName(response.fileName.split("\\").pop());
        })
        .catch(error => {
        })
        .finally(() => {
        })
    } else {
      return;
    }
  }

  const onGetElement = async () => {
    if (loading) {
      await fetch(getApiURL('/GetElementByPk/' + location.state.id))
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then(response => {
          setData(response);
          setElementId(response.id_ELEMENT);
          setSaler(response.saler);
          setSerialCode(response.serialCode);
          setElement(response.elementName);
          setInitSaler(response.initSaler);
          setInitSerialCode(response.initSerialCode);
          setInitElement(response.InitElement);
          setStatus(response.status);

          setPolygons(ElementToPolygons(response));

          //図面　フロア1 > 床 > 部屋1 > エリア1
          const label = "図面 " + response.id_FLOOR.floorName + ">"
            + zoneToName(response.zone) + ">"
            + response.roomName + ">" + response.areaName;

          setImageLabel(label);

          fetchImageFloor(response.id_FLOOR.id);
        })
        .catch(error => {
          console.error("Failed to fetch the api: ", error);
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      return;
    }
  }

  const fetchImageFloor = async (floorId) => {
    try {
      const image = await axios.get(getApiURL(`/GetImageFloor/${floorId}`), {
        responseType: 'arraybuffer'
      });
      const base64 = btoa(
        new Uint8Array(image.data).reduce(
          (image, byte) => image + String.fromCharCode(byte),
          ''
        )
      );
      setImageFloor(`data:;base64,${base64}`);
    } catch (error) {
      console.log(error);
    }
  }


  const onSave = async (e, arg) => {

    if (serialCode.length === 0
      || (status != "5" && status != "6" && status != "7")) {
      alert("必須項目を入力してください。")
      return;
    }

    const saveElement = {
      method: 'PUT',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        "id_ELEMENT": elementId,
        "serialCode": serialCode,
        "elementName": element,
        "status": status,
        "saler": saler,
        "isAnswered": 1
      })
    };
    fetch(getApiURL("/UpdateElement/"), saveElement)
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          if (e.target.getAttribute("name") === 'top') {
            GoToTop(navigate, location, display_id);
          } else if (e.target.getAttribute("name") === 'continue') {
            onContinue();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onContinue = async () => {
    await fetch(getApiURL(`/getElementByIdLimit/${display_id}/` + elementId))
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(response => {
        setData(response);
        setElementId(response.id_ELEMENT);
        setElement(response.elementName);
        setSaler(response.saler);
        setSerialCode(response.serialCode);
        setInitSaler(response.initSaler);
        setInitSerialCode(response.initSerialCode);
        setInitElement(response.InitElement);
        setStatus(response.status);

        setPolygons(ElementToPolygons(response));

        //図面　フロア1 > 床 > 部屋1 > エリア1
        const label = "図面 " + response.id_FLOOR.floorName + ">"
          + zoneToName(response.zone) + ">"
          + response.roomName + ">" + response.areaName;
        setImageLabel(label);

        setImageFloor(null);
        fetchImageFloor(response.id_FLOOR.id);
      })
      .catch(error => {
        console.error("Failed to fetch the api: ", error);
        alert("次の質疑はありません。");
        GoToTop(navigate, location, display_id);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const onReset = () => {
    setElement(initElement);
    setSaler(initSaler);
    setSerialCode(initSerialCode);
    setStatus("");
  }

  const onCancel = async () => {
    GoToTop(navigate, location, display_id);
  }

  React.useEffect(() => {
    onGetElement();
    getAnswer();
  }, [loading])
  return (
    <>
      <Head title={"品番選択"} />
      <CssBaseline />
      <Box height={"100vh"} display={"flex"} flexDirection={"column"}>
        <Header />
        <Container maxWidth="lg" sx={{ paddingY: { xs: "30px", md: "40px" } }}>
          <Card>
            <CardHeader text={"品番選択"} />
            <CardBody>
              <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: 'row-reverse',
                mb: 1
              }}>
                ファイル名：{fileName}
              </Box>
              <Grid container justifyContent="space-between">
                <Grid item sx={{ width: { xs: "100%", md: "calc(100% - 300px)" } }}>
                  <Card>
                    <CardHeaderLayer2 text={imageLabel} />
                    <CardBody>
                      {imageFloor && data ? <Grid container justifyContent="center">
                        <DrawCanvasElement imgSrc={imageFloor}
                          element={data} />
                      </Grid>
                        : <Grid container justifyContent="center">
                          <Grid>
                            <CircularProgress />
                          </Grid>
                        </Grid>}
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item sx={{ width: { xs: "100%", md: "280px" } }} mt={{ xs: 3, md: 0 }}>
                  <Card>
                    <CardHeaderLayer2 text={"入力項目"} />
                    <CardBodyLayer2>
                      <Box>
                        <Typography>部材名</Typography>
                        <TextField
                          InputProps={{
                            readOnly: location.state.answered,
                          }}
                          onChange={e => setElement(e.target.value)}
                          value={element}
                          placeholder={element}
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small" />
                      </Box>
                      <Box mt={2}>
                        <Typography>販売元</Typography>
                        <TextField
                          InputProps={{
                            readOnly: location.state.answered,
                          }}
                          onChange={e => setSaler(e.target.value)}
                          value={saler}
                          placeholder={saler}
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small" />
                      </Box>
                      <Box mt={2}>
                        <Typography>品番<span style={markStyle}>＊</span></Typography>
                        <TextField
                          InputProps={{
                            readOnly: location.state.answered,
                          }}
                          onChange={e => setSerialCode(e.target.value)}
                          value={serialCode}
                          placeholder={serialCode}
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          required={true} />
                      </Box>
                      <Box mt={2}>
                        <Typography>ステータス<span style={markStyle}>＊</span></Typography>
                        <FormControl fullWidth>
                          <Select
                            inputProps={{
                              readOnly: location.state.answered,
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label=""
                            size="small"
                            onChange={handleChange}
                            required={true}
                          >
                            <MenuItem value={5}>未確定</MenuItem>
                            <MenuItem value={6}>シリーズのみ確定</MenuItem>
                            <MenuItem value={7}>部材確定</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box mt={2}>
                        <Typography>※未確定でも保存できます。</Typography>
                      </Box>
                    </CardBodyLayer2>
                  </Card>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
              <Grid container justifyContent="end" spacing={1}>
                <Grid item xs={12} md={"auto"} sx={{ marginRight: "auto" }}>
                  <CancelButton onClick={onCancel} />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <ResetButton
                    onClick={onReset}
                    disabled={location.state.answered} />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <MainButton onClick={onSave} name="top" text={"保存してトップへ"}
                    disabled={location.state.answered} />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <MainButton onClick={onSave} name="continue" text={"保存して次へ"}
                    disabled={location.state.answered}
                  />
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </Container>
        <Box mt={"auto"}>
          <Footer />
        </Box>
      </Box>
    </>
  )
}
export default ProductNumberPage;