import * as React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from "@mui/material";
import MainButton from "../buttons/MainButton";
import pen from '../../img/pen-to-square-regular.svg';
import { GoToEditElement } from '../../util/NavigatePage';

const column = {
  floorName: 'フロア名',
  zone: 'モード',
  roomName: '部屋名',
  areaName: 'エリア',
  elementName: '部材名',
  saler: '販売元',
  serialCode: '品番',
  status: 'ステータス',
  isAnswered: '回答'
};


const statusChange = (status) => {
  if (status === 5) {
    return "未確定";
  } else if (status === 6) {
    return "シリーズのみ確定";
  } else if (status === 7) {
    return "部材確定";
  } else {
    return "";
  }
}

const zoneChange = (zone) => {
  switch (zone) {
    case 100:
      return "床";
    case 200:
      return "天井";
    case 300:
      return "壁";
    case 400:
      return "間仕切";
    case 500:
      return "建材";
    case 600:
      return "巾木";
  }
  return "";
}

export default function BasicTable({ productData }) {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  return (
    productData?.map((row) => {
      return (
        <Box mt={2}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {function () {
                  let table = [];
                  const edit = (<TableRow key={-1}>

                    <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
                      align="center">{"編集"}</TableCell>
                    <TableCell align="center"><img src={pen} alt="" width={15} height={15} onClick={() => GoToEditElement(navigate, location, row.id_ELEMENT, display_id)} /></TableCell>
                  </TableRow >);
                  table.push(edit);
                  for (const key in column) {
                    if (key == "status") {
                      table.push(<TableRow key={key}>
                        <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
                          align="center">{column[key]}</TableCell>
                        <TableCell align="center">{
                          statusChange(row.status)

                        }</TableCell>
                      </TableRow>)
                    } else if (key == "zone") {
                      table.push(<TableRow key={key}>
                        <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
                          align="center">{column[key]}</TableCell>
                        <TableCell align="center">{
                          zoneChange(row.zone)
                        }
                        </TableCell>
                      </TableRow>)
                    } else if (key == "isAnswered") {
                      table.push(<TableRow key={key}>
                        <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
                          align="center">{column[key]}</TableCell>
                        <TableCell align="center">
                          {
                            function () {
                              return row.isAnswered === 1 ? "回答済み" : "未回答"
                            }()
                          }
                        </TableCell>
                      </TableRow>)
                    } else if (key == "floorName") {
                      table.push(<TableRow key={key}>
                        <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
                          align="center">{column[key]}</TableCell>
                        <TableCell align="center">
                          {
                            function (row) {
                              return (row["id_FLOOR"]["floorName"]);
                            }(row)
                          }
                        </TableCell>
                      </TableRow>)
                    } else if (key in row) {
                      table.push(<TableRow key={key}>
                        <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
                          align="center">{column[key]}</TableCell>
                        <TableCell align="center">{
                          function (row, key) {
                            if (key === "productNumber") {
                              return (
                                <MainButton style={{ width: "auto" }} onClick={() => GoToEditElement(navigate, location, row.id_ELEMENT, display_id)}
                                  text={row[key]} />
                              );
                            } else {
                              return row[key];
                            }
                          }(row, key)
                        }</TableCell>
                      </TableRow>)
                    }
                  }
                  return table;
                }()}
              </TableBody >
            </Table >
          </TableContainer >
        </Box >)
    })
  );
}
