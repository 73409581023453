import { useEffect, useState } from "react";
import Canvas from "react-canvas-polygons";
import { getImageRatio } from "../../util/UtilFunctions";
import { DrawFree } from "../../util/Canvas";

const DrawCanvas = ({ imgSrc, frees }, ref) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const img = new Image();

  // 画像が読み込まれたら、画像のサイズを取得する
  img.onload = () => {
    setWidth(img.width * getImageRatio());
    setHeight(img.height * getImageRatio());
  };

  //読み込めなかった場合
  img.onerror = () => {
    setWidth(600);
    setHeight(500);
  };
  img.src = imgSrc;

  useEffect(() => {
  }, []);

  return (
    <div>
      <DrawFree imgSrc={imgSrc} frees={frees} />
    </div>
  );
};

export default DrawCanvas;
