import logo from "../img/s-build-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { Container } from "@mui/material";
import Head from "../components/head/Head";
import { useState } from "react";
import axios from "axios";
import { getApiURL } from "../util/ApiBase";
import { GoToTopWithLogin } from "../util/NavigatePage";

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const displayId = searchParams.get('display_id');

  const [text, setText] = useState("");
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(true);

  const routeChange = async () => {
    if (text === "") {
      setError(true);
      return;
    }
    const loginParam = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        "displayId": displayId,
        "passcode": text
      })
    };
    await fetch(getApiURL("/login/"), loginParam)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((response) => {
        if (response[0]) {

          if (response[0].invalidatedDateTime) {
            alert("このURLは失効しています。送信者にお問い合わせください。");
          } else {
            const answered = !!response[0].submitDateTime;
            GoToTopWithLogin(navigate, response[0].displayId, answered);
          }
        } else {
          throw response;
        }
      })
      .catch((error) => {
        setText("");
        console.error("Failed to fetch the api: ", error);
        alert("パスコードまたはURLが間違っています");
        setError(error);
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const returnTextField = () => {
    let label = "";

    if (error) {
      return (
        <TextField
          sx={{ width: { xs: "300px" }, marginTop: "60px" }}
          id="outlined-basic"
          label="パスコードまたはURL間違っています"
          value={text}
          variant="outlined"
          error
          onChange={(event) => setText(event.target.value)}
        />
      );
    } else {
      return (
        <TextField
          sx={{ width: { xs: "300px" }, marginTop: "60px" }}
          id="outlined-basic"
          label="パスコードを入力してください"
          value={text}
          variant="outlined"
          onChange={(event) => setText(event.target.value)}
        />
      );
    }
  };

  return (
    <>
      <Head title={"ログイン"} />
      <Container maxWidth="lg">
        <Grid
          sx={{ height: "100vh", paddingBottom: "100px" }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <img width={"100%"} className="" src={logo} alt="logo" />
            {returnTextField()}
            <Button
              size={"large"}
              sx={{ marginTop: "20px" }}
              variant="contained"
              onClick={routeChange}
            >
              ログイン
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default LoginPage;
