import { useLocation, useNavigate } from "react-router-dom";

export function GoToTop(navigate, location, displayId) {
    if (location.state?.logined) {
        if (window.location.pathname == "/A110_Top") {
            navigate(0);
        } else {
            navigate(`/A110_Top?display_id=${displayId}`, { state: { logined: "logined", answered: location.state?.answered } });
        }

    } else {
        navigate(`?display_id=${displayId}`);
    }
}

export function GoToHistory(navigate, location, displayId) {
    if (location.state?.logined) {
        navigate(`/A140_History?display_id=${displayId}`, { state: { logined: "logined", answered: location.state?.answered } });
    } else {
        navigate(`?display_id=${displayId}`);
    }
}


export function GoToTopWithLogin(navigate, displayId, answered) {
    navigate(`/A110_Top?display_id=${displayId}`, { state: { logined: "logined", answered: answered } });
}

export function GoToLogin(navigate, displayId) {
    navigate(`/?display_id=${displayId}`);
}

export function GoToEditElement(navigate, location, id, displayId) {
    navigate(`/A120_Element?display_id=${displayId}`, { state: { id: id, logined: "logined", answered: location.state?.answered } });
}

export function GoToEditFreeQuestion(navigate, location, id, displayId) {
    navigate(`/A130_Free_Question?display_id=${displayId}`, { state: { id: id, logined: "logined", answered: location.state?.answered } });
}

export function GoToThanks(navigate, displayId) {
    navigate(`/A200_Thanks?display_id=${displayId}`);
}
