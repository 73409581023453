import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Unstable_Grid2";
import CancelButton from "../buttons/CancelButton";
import MainButton from "../buttons/MainButton";
import { getApiURL } from "../../util/ApiBase";
import { GoToTop } from '../../util/NavigatePage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 350, md: 400 },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ResetModal(props) {
  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');


  const Reset = async () => {
    const initData = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: ""
    };
    fetch(getApiURL(`/init/${display_id}`), initData)
      .then((res) => {
        if (res.status) {
          GoToTop(navigate, location, display_id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Box bgcolor={"cardHeaderColor.main"} height={"50px"} borderRadius={1} display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                確認
              </Typography>
            </Box>
            <Box mt={5}>
              <Typography id="transition-modal-description">
                すべての質問が初期化されます<br />
                リセットしますか？
              </Typography>
            </Box>
            <Box bgcolor={"cardFooterColor.main"} borderRadius={1} mt={5} px={{ xs: 2, md: 3 }} py={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <CancelButton onClick={props.handleClose} />
                </Grid>
                <Grid item>
                  <MainButton onClick={() => Reset()} text={"リセットします"} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
