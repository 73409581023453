import * as React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { GoToEditFreeQuestion } from '../../util/NavigatePage';

const column = {
  floorName: 'フロア名',
  questionIndex: '番号',
  question: '質疑内容',
  answer: '質疑回答',
  isAnswered: '回答'
};

const createRow = (key, content) => {

  return (
    <TableRow key={key}>
      <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
        align="center">{column[key]}</TableCell>
      <TableCell align="center">{
        content
      }
      </TableCell>
    </TableRow>
  )
}

const createTables = (navigate, location, floorData, display_id) => {

  // テーブル全体
  let tables = [];
  // 全てのデータ
  floorData.forEach(data => {
    // テーブル全体の中にテーブルを一個一個詰めていく
    tables.push(data.floorDataList?.map(floorData => {

      return (
        <Box mt={2}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {function () {
                  let rowList = [];
                  for (const key in column) {
                    if (key == "floorName") {
                      rowList.push(
                        <TableRow key={key} onClick={() => GoToEditFreeQuestion(navigate, location, data.floor, display_id)}>
                          <TableCell sx={{ backgroundColor: "cellHeaderColor.main" }} component="th" scope="row"
                            align="center">{column[key]}</TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <Link to={"/A130_Free_Question"}><Button variant="contained">{data.floorName}</Button></Link>
                          </TableCell>
                        </TableRow>
                      )
                    } else if (key == "isAnswered") {
                      const content = floorData[key] === 0 ? "未回答" : "回答済み";
                      rowList.push(createRow(key, content));
                    } else if (key in floorData) {
                      rowList.push(createRow(key, floorData[key]));
                    }
                  }
                  return rowList;
                }()}
              </TableBody>
            </Table>
          </TableContainer>
        </Box >
      )
    }))
  })
  return tables;
}


export default function BasicTable({ floorData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  return createTables(navigate, location, floorData, display_id);
}
