import { Box, Button, Container, CssBaseline, Card } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Head from "../components/head/Head";
import CardHeader from "../components/card/CardHeader";
import CardBody from "../components/card/CardBody";
import * as React from "react";
import { GoToTop, GoToLogin } from "../util/NavigatePage";
import HistoryProductNumber from "../components/table/HistoryProductNumber";
import HistoryQAndA from "../components/table/HistoryQAndA";


function History() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  //直リンク等防止
  if (location?.state?.logined) {
    console.log(location?.state?.logined)
  } else {
    GoToLogin(navigate, display_id);
  }

  return (
    <>
      <Head title={"回答履歴"} />
      <CssBaseline />
      <Header />
      <Container maxWidth="lg" sx={{ paddingY: { xs: "20px", md: "40px" } }}>
        <Box mb={2}>
          <Button onClick={() => {
            GoToTop(navigate, location, display_id);
          }}
            size={"middle"} variant="contained"
          >トップに戻る</Button>
        </Box>
        <Card>
          <CardHeader text={"回答履歴"} />
          <CardBody>
            <Box>
              <Card>
                <CardHeader text={"品番履歴"} />
                <CardBody>
                  <HistoryProductNumber />
                </CardBody>
              </Card>
            </Box>
            <Box mt={2}>
              <Card>
                <CardHeader text={"質疑履歴"} />
                <CardBody>
                  <HistoryQAndA />
                </CardBody>
              </Card>
            </Box>

          </CardBody>
        </Card>

      </Container>
      <Footer />
    </>
  )
}

export default History;
