import { useEffect, useState } from "react";
import Canvas from "react-canvas-polygons";
import { DrawElement } from "../../util/Canvas";

const DrawCanvasElement = ({ imgSrc, element }, ref) => {

  useEffect(() => {

  }, []);
  return (
    <div>
      {element ? <DrawElement imgSrc={imgSrc} element={element} /> : <></>}
    </div>
  );
};

export default DrawCanvasElement;
