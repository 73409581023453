import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import TopPage from "../pages/TopPage";
import ProductNumberPage from "../pages/ProductNumberPage";
import QAndAPage from "../pages/QAndAPage";
import History from "../pages/History";
import ThanksPage from "../pages/ThanksPage";
import { Box } from "@mui/material";

function SettingRoutes() {
  return (
    <Box bgcolor={"pageBgColor.main"}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/A110_Top" element={<TopPage />} />
          <Route path="/A120_Element" element={<ProductNumberPage />} />
          <Route path="/A130_Free_Question" element={<QAndAPage />} />
          <Route path="/A140_History" element={<History />} />
          <Route path="/A200_Thanks" element={<ThanksPage />} />
          <Route path="/ui_health_check" element={<></>} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default SettingRoutes;
