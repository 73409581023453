import { Button, Box, Container, CssBaseline, Typography, Card, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useLocation, useNavigate } from "react-router-dom";
import ProductNumberTable from "../components/table/ProductNumberTable";
import ProductNumberTableSp from "../components/table/ProductNumberTableSp";
import QAndAStatusTable from "../components/table/QAndAStatusTable";
import QAndAStatusTableSp from "../components/table/QAndAStatusTableSp";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Head from "../components/head/Head";
import CardHeader from "../components/card/CardHeader";
import CardBody from "../components/card/CardBody";
import * as React from "react";
import ConfirmSubmitModal from "../components/modal/ConfirmSubmitModal";
import QAData from "../jsonData/table/dummydata.json";
import axios from "axios";
import { getApiURL } from "../util/ApiBase";
import { GoToLogin } from "../util/NavigatePage";

const reducer = (state, action) => {
  switch (action.type) {
    case 'setFreeQuestion':
      return [...state, action.data]
    default:
      return state;
  }
}

function TopPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');

  //State for elements
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');

  //State for questionnaire
  const [questionnaireId, setQuestionnaireId] = React.useState(0);
  const [questionnaire, setQuestionnaire] = React.useState(null);
  const [note, setNote] = React.useState("");
  const [noteLoading, setNoteLoading] = React.useState(true);
  const [questError, setQuestError] = React.useState('');
  const [dueDate, setDueDate] = React.useState('');
  const [fileName, setFileName] = React.useState('');

  //Floor Question
  const [floor, setFloor] = React.useState([]);
  const [floorLoading, setFloorLoading] = React.useState(true);
  const [freeQuestion, dispatch] = React.useReducer(reducer, []);


  //Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




  //直リンク等防止
  if (location?.state?.logined) {
    console.log(location?.state?.logined)
  } else {
    GoToLogin(navigate, display_id);
  }

  const checkIsAnswered = async () => {
    try {
      const element = await axios.get(getApiURL(`/GetIsAnsweredElement/${display_id}/0`));
      const freeQuestion = await axios.get(getApiURL(`/GetFreeQuestionByIsAnswered/${display_id}/0`));

      if (element.data.length !== 0 && freeQuestion.data.length !== 0) {
        alert('未回答の項目があります。すべて回答してください。');
      } else {
        handleOpen();
      }
    } catch (error) {
      console.log(error);
    }
  }

  //Fetch Elements
  const fetchElement = async () => {
    if (loading && data === null) {
      await fetch(getApiURL(`/GetDisplayId/${display_id}`))
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then(response => {
          setData(response);
        })
        .catch(error => {
          console.error("Failed to fetch the api: ", error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      return;
    }
  }
  //End of Fetch Elements

  //Fetch Questionnaire
  const fetchNote = async () => {
    if (noteLoading) {
      if (note === "") {
        await fetch(getApiURL(`/questionnaireById/${display_id}`))
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw response;
          })
          .then(response => {
            setNote(response.note);
            setQuestionnaireId(response.id_QUESTIONNAIRE);
            setQuestionnaire(response);
            setFileName(response.fileName.split("\\").pop());
            const date = new Date(response.dueDateTime);
            const dateStr = date.getFullYear() + "年"
              + (date.getMonth() + 1) + "月"
              + date.getDate() + "日";
            setDueDate(dateStr)
          })
          .catch(error => {
            console.error("Failed to fetch the api: ", error);
            setQuestError(error);
          })
          .finally(() => {
            setNoteLoading(false);
          })
      } else {
        return;
      }
    } else {
      return;
    }
  }

  const onUpdateNote = async () => {
    const saveNote = {
      method: 'PUT',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        "id_QUESTIONNAIRE": questionnaireId,
        "note": note
      })
    };

    await fetch(getApiURL('/UpdateNote/'), saveNote)
      .then((res) => res.json())
      .then((response) => {
        setNote(response.note);
        alert("物件メモが保存されました。")
        setQuestionnaire(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //End of Questionnaire

  //Fetch Floor
  const fetchFloor = async () => {
    if (floorLoading) {
      await fetch(getApiURL(`/GetFloorByDisplayId/${display_id}`))
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then(response => {
          setFloor(response);
        })
        .catch(error => {
          console.error("Failed to fetch the api: ", error);
          setError(error);
        })
        .finally(() => {
          setFloorLoading(false);
        })
    } else {
      return;
    }
  }

  const freeQuestCallback = React.useCallback(
    () => {
      QAData = [];
      let container = {};
      floor.map(async (item) => {
        await fetch(getApiURL('/free_questionsByFloor/' + item.id))
          .then(response => {
            if (response.ok) {
              return response.json();
            }
          })
          .then(response => {
            response.map(function (element, index, res) {
              container = {
                floor: item.id,
                floorName: item.floorName,
                floorIndex: item.floorIndex,
                idQuestionnaire: item.id_QUESTIONNAIRE,
                floorDataList: res
              }
            });
            QAData.push(container);
            dispatch({ type: 'setFreeQuestion', data: container });
          }).catch((error) => {
            console.log(error);
          });
      });

    }, [floor]
  );

  const sortedData = QAData.sort((a, b) => a.floor - b.floor);
  //End of Fetch Floor Questions

  React.useEffect(() => {
    fetchElement();
  }, [loading]);

  React.useEffect(() => {
    fetchNote();
  }, [noteLoading])

  React.useEffect(() => {
    fetchFloor();
    if (floor.length != 0) {
      freeQuestCallback();
    }
  }, [floorLoading])

  return (
    <>
      <Head title={"トップ"} />
      <CssBaseline />
      <Header />
      <Container maxWidth="lg" sx={{ paddingY: { xs: "20px", md: "40px" } }}>
        <Card>
          <CardHeader text={"品番選択"} />
          <CardBody>
            <Typography fontSize={22} fontWeight={600}>
              下記の材料の確定品番をご回答ください。
            </Typography>

            <Box display={{ xs: "block", md: "flex" }} mt={1}>
              <Typography>
                編集アイコンをクリックすると品番選択画面に移ります。<br />
                <Typography sx={{ color: "#f00" }}>
                  再度URLから開くと、続きから操作できます。
                </Typography>
              </Typography>
              <Typography sx={{ marginLeft: "auto" }}>
                <Typography>回答期限：{dueDate}まで</Typography>
                <Typography>ファイル名：{fileName}</Typography>
              </Typography>

            </Box>
            <Box display={{ xs: "none", md: "block" }} mt={3}>
              {
                loading && <Typography>読み込み中・・・。</Typography>
              }{
                error && (
                  <Typography>{`投稿データの取得に問題があります。${error}`}</Typography>
                )
              }
              <ProductNumberTable productData={data} />
            </Box>
            <Box display={{ xs: "block", md: "none" }} mt={2}>
              <ProductNumberTableSp productData={data} />
            </Box>
          </CardBody>
        </Card>

        <Card sx={{ marginTop: { xs: "30px", md: "60px" } }}>
          <CardHeader text={"質疑一覧"} />
          <CardBody>
            <Typography fontSize={22} fontWeight={600}>
              下記の質問にご回答ください。
            </Typography>
            <Typography mt={1}>

              編集アイコンをクリックする回答画面に移ります。
            </Typography>
            <Box display={{ xs: "none", md: "block" }} mt={3}>
              {
                loading && <Typography>読み込み中・・・。</Typography>
              }{
                error && (
                  <Typography>{`投稿データの取得に問題があります。${error}`}</Typography>
                )
              }
              <QAndAStatusTable floorData={sortedData} />
            </Box>
            <Box display={{ xs: "block", md: "none" }} mt={2}>
              <QAndAStatusTableSp floorData={sortedData} />
            </Box>
          </CardBody>
        </Card>

        <Card sx={{ marginTop: { xs: "30px", md: "60px" } }}>
          <CardHeader text={"物件メモ"} />
          <CardBody>

            <Typography fontSize={22} fontWeight={600} mb={2}>
              本物件に関して、何かございましたらご記入ください。
            </Typography>
            <TextField
              InputProps={{
                readOnly: location.state.answered,
              }}
              id="outlined-multiline-static"
              label=""
              multiline
              rows={8}
              defaultValue=""
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <Box mt={{ xs: 2, md: 3 }} display={"flex"} justifyContent={"end"}>
              <Button
                disabled={location.state.answered}
                onClick={() => onUpdateNote()} size={"large"} variant="contained">保存</Button>

            </Box>
          </CardBody>
        </Card>

        <Grid container justifyContent="end" mt={{ xs: 5, md: 8 }}>
          <Button
            disabled={location.state.answered}
            variant="contained" onClick={checkIsAnswered} sx={{ width: "150px", height: "60px" }}>回答</Button>
          <ConfirmSubmitModal open={open} note={note} questionnaireId={questionnaireId} handleClose={handleClose} />
        </Grid>
      </Container>
      <Footer />
    </>
  )
}

export default TopPage;
