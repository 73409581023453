import Button from "@mui/material/Button";
import React from "react";
import { GoToLogin } from "../../util/NavigatePage";
import { useLocation, useNavigate } from "react-router-dom";

function MainButton() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const display_id = searchParams.get('display_id');



  const style = {
    backgroundColor: "#e0e0e0"
  }

  return (
    <Button onClick={() => {
      GoToLogin(navigate, display_id)
    }} style={style} size={"small"} variant="outlined" color="logout">ログアウト</Button>
  );
}

export default MainButton;
